<template>
  <div id="match-big-loan-product">
    <div class="waiting-loading">
      <img
          src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/match-big-loan-product-loading/background.gif"
          alt=""
          class="background"
      />
      <main>
        <div>
          <span :class="waitingTime > 0 ? 'text-active' : ''"
          >正在校验账号安全性</span
          >
          <img
              src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/match-big-loan-product-loading/waiting.gif"
              alt=""
              v-show="waitingTime === 0"
          />
          <img
              src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/match-big-loan-product-loading/active.png"
              alt=""
              v-show="waitingTime > 0"
          />
        </div>
        <div>
          <span :class="waitingTime > 1 ? 'text-active' : ''"
          >正在核验额度有效期</span
          >
          <img
              src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/match-big-loan-product-loading/not-active.png"
              alt=""
              v-show="waitingTime < 1"
          />
          <img
              src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/match-big-loan-product-loading/waiting.gif"
              alt=""
              v-show="waitingTime === 1"
          />
          <img
              src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/match-big-loan-product-loading/active.png"
              alt=""
              v-show="waitingTime > 1"
          />
        </div>
        <div>
          <span :class="waitingTime > 2 ? 'text-active' : ''"
          >正在匹配额度产品方</span
          >
          <img
              src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/match-big-loan-product-loading/not-active.png"
              alt=""
              v-show="waitingTime < 2"
          />
          <img
              src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/match-big-loan-product-loading/waiting.gif"
              alt=""
              v-show="waitingTime >= 2 && !waitingEndAndProductHasBeenGet"
          />
          <img
              src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/match-big-loan-product-loading/active.png"
              alt=""
              v-show="waitingEndAndProductHasBeenGet"
          />
        </div>
      </main>
    </div>
    <div class="count-down-cover" v-show="countDownCoverIsShow">
      <img
          src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/loan-info/count-down-cover-image.gif"
          alt=""
      />
    </div>
    <van-popup
        v-model="protocolPopupIsShow"
        position="bottom"
        :close-on-click-overlay="false"
        round
    >
      <div class="protocol-popup-container">
        <div
            class="protocol-content-container"
            ref="protocol-content-container"
        >
          <van-tabs v-model="protocolsTitleIndex" animated :ellipsis="false">
            <van-tab
                v-for="(item, index) in bigAgreementName"
                :title="item"
                :key="index"
            >
              <div
                  v-if="item === '个人信息授权书' && protocolPopupIsShow"
                  v-html="protocolContentHtml"
                  :class="{
                  'protocol-content': true,
                  'protocol-content-no-scroll': stopSubmit,
                }"
                  ref="content"
              ></div>
              <iframe
                  v-else
                  :src="
                  bigAgreementName.length === 2
                    ? bigAgreementFile[0]
                    : bigAgreementFile[index]
                "
                  frameborder="0"
              />
            </van-tab>
          </van-tabs>
        </div>
        <div
            class="protocol-do-agree-button"
            v-throttle="2000"
            @click="doSubmit"
        >
          我同意并申请
        </div>
        <div class="protocol-refuse-button" @click="refuseProtocol">
          我不同意
        </div>
      </div>
    </van-popup>
    <van-popup
        round
        v-model="refuseProtocolPopupIsShow"
        :close-on-click-overlay="false"
    >
      <div class="refuse-protocol-popup-container">
        <h3>流程太繁琐？</h3>
        <p>我们采用自动化AI匹配</p>
        <p>您所填写的资料我们会高度保密</p>
        <img
            src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/loan-info/finish-apply-button.png"
            alt=""
            v-throttle="2000"
            @click="doSubmit"
        />
        <img
            src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/loan-info/to-super-loan-button.png"
            alt=""
            @click="applyCancel"
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
import {get_config_by_key} from "_network/config"
import {get_agreement_list} from "_network/submitInfo"
import {api_product_user_apply} from "_network/statisticsUv"
import {
  match_product_by_post_user_message,
  apply_big_loan_product,
  save_personal_protocol_image
} from "_network/product"
import {apply_api_product} from "_network/apiProduct"

import personalContent from "_utils/personalContent"
import getNowDate from "_utils/time"
import html2canvas from "html2canvas"
import getSearch from "_utils/getSearch"

export default {
  data() {
    return {
      // 初次进入页面匹配产品时的倒计时
      waitingTime: 0,
      countDownCoverIsShow: false,
      // 多个协议列表时默认展示的协议索引值
      protocolsTitleIndex: 0,
      // 协议名称展示集合
      bigAgreementName: ["个人信息授权书"],
      // 协议展示地址集合
      bigAgreementFile: [],
      // 个人信息授权书内容
      protocolContentHtml: "",
      // 个人信息授权书弹窗显隐状态
      protocolPopupIsShow: false,
      // 该状态在协议弹框弹出时为true，在协议截图完成时改为false，用来阻止用户滑动协议以及点击提交申请的按钮
      stopSubmit: false,
      // 产品
      product: {},
      // 该值代表产品是否已经获取到
      productHasBeenGet: false,
      // 用户点击协议弹框的 我不同意 后弹出的确认弹框
      refuseProtocolPopupIsShow: false
    }
  },
  computed: {
    // 该值只有在初次进入页面的3s加载动画已结束，并且产品已经得到时会返回true
    waitingEndAndProductHasBeenGet() {
      return this.productHasBeenGet && this.waitingTime > 2
    }
  },
  watch: {
    waitingEndAndProductHasBeenGet(state) {
      if (state) {
        this.afterMatchProductAndBeforePopupProtocol()
      }
    }
  },
  methods: {
    // 获取配置项
    async getConfig() {
      // 该配置项为大额贷申请成功页面自动跳转的倒计时，单位s
      get_config_by_key(
          "big_loan_apply_success_skip_count_down_config_key"
      ).then(({code, data}) => {
        if (code === 200) {
          localStorage.setItem("examiningToSuperLoanCountDown", data)
        }
      })
    },
    // 匹配产品
    async matchProduct() {
      // 处理匹配时的资料参数
      const userInfo = this.$getUserInfo()

      // 正式提交资料匹配产品
      try {
        // 这里需要判断是否匹配过产品了
        const product = localStorage.getItem("bigLoanProduct")
        if (product) {
          this.product = JSON.parse(product)
        } else {
          const { data } = await match_product_by_post_user_message(userInfo)
          localStorage.setItem("bigLoanProduct", JSON.stringify(data))
          this.product = JSON.parse(localStorage.getItem("bigLoanProduct"))
          if (
              userInfo.city === "北京市" &&
              process.env.VUE_APP_SPECIAL_CITY
          ) {
            this.product.productType = 0
          }
        }

        // 产品已经获取完毕
        this.productHasBeenGet = true
        // 下一步判断必须在3s的倒计时动画结束后进行，所以在waitingEndAndProductHasBeenGet变量的监听器中执行后面的函数
      } catch (e) {
        if (e.message === "24小时内只能修改五次信息！") {
          await this.$router.replace("/special")
        }
      }
    },
    // 在获取产品后，弹出协议之前做的判断
    async afterMatchProductAndBeforePopupProtocol() {
      // 这里决定了协议不弹出情况下的跳转逻辑
      try {
        // 没有产品直接跳贷超
        if (!this.product) {
          localStorage.setItem("submitSuccess", "1")
          await this.$router.replace("/superLoan")
          return
        }

        // 这里获取到的产品如果是极速贷(productType为0)则直接跳转贷超，否则弹出协议并获得截图
        if (this.product.productType === 0) {
          // 该渠道直接跳失败，并且永远无法到达贷超
          if (process.env.VUE_APP_FAILED_TO_FAIL_PAGE) {
            await this.$router.replace("/fail")
            return
          }

          // 该渠道直接跳失败，但是成功后的流程是正常的，能够前往贷超
          if (process.env.VUE_APP_FAILED_TO_FAIL_PAGE_BUT_CAN_TO_SUPER_LOAN) {
            await this.$router.replace("/fail")
            return
          }

          // 该渠道没有匹配到产品就直接跳下载页面
          if (process.env.VUE_APP_FAILED_TO_DOWNLOAD) {
            this.$toDownload(0)
            return
          }

          localStorage.setItem("submitSuccess", "1")
          await this.$router.replace("/special")
        } else {
          // 这里是匹配到了大额贷的情况，如果是本地api产品则不会弹窗，直接进件然后前往api中间页
          if (
              this.product.instApiState === 1 &&
              (this.product.localState === 1 || this.product.localState === 2)
          ) {
            // 该渠道匹配到本地api直接跳下载
            if (process.env.VUE_APP_SUPER_LOAN_ONLY_LOCAL_STATE) {
              this.$toDownload(0)
              return
            }

            // api产品存入本地，在后续中间页需要使用
            localStorage.setItem(
                "apiProduct",
                JSON.stringify(this.product)
            )
          }

          // 这里是大额贷或者api的h5产品，正常弹出协议即可
          this.replaceProtocolContent()
        }
      } catch (e) {
      }
    },
    //获取个人信息授权书协议
    async getProtocolContentHtml() {
      this.bigAgreementName = ["个人信息授权书"]
      this.bigAgreementFile = []
      try {
        const {data} = await get_agreement_list()
        data.forEach((protocol) => {
          if (protocol.name === "个人信息授权书") {
            this.protocolContentHtml = protocol.content
            if (process.env.VUE_APP_IS_SPECIAL_PERSONAL) {
              this.protocolContentHtml = personalContent
            }
            if (process.env.VUE_APP_PROTOCOL_PLATFORM_NAME) {
              this.protocolContentHtml = this.protocolContentHtml.replaceAll(
                  "{productName}",
                  process.env.VUE_APP_PROTOCOL_PLATFORM_NAME
              )
            }
            if (process.env.VUE_APP_PROTOCOL_COMPANY_NAME) {
              this.protocolContentHtml = this.protocolContentHtml.replaceAll(
                  "{productCompanyName}",
                  process.env.VUE_APP_PROTOCOL_COMPANY_NAME
              )
            }
            localStorage.setItem("agreementId", protocol.id)
          }
        })

        // 该渠道不会匹配产品，延时3s直接弹出协议接口
        if (process.env.VUE_APP_TO_IN_REVIEW_AFTER_SUBMIT) {
          setTimeout(() => {
            this.protocolPopupIsShow = true
          }, 3000)
          return
        }

        // 获取协议之后可以开始匹配产品
        await this.matchProduct()
      } catch (e) {
      }
    },
    // 处理协议，替换协议内容，弹出协议并获得截图
    replaceProtocolContent() {
      let protocolList = []
      if (
          this.product.xdApiAgreementList &&
          this.product.xdApiAgreementList.length !== 0
      ) {
        protocolList = this.product.xdApiAgreementList
      }

      // 把protocolList循环分别赋值给bigAgreementNameList和bigAgreementFileList
      this.product.bigAgreementName = protocolList.map(
          (protocol) => protocol.name
      )
      this.product.bigAgreementFile = protocolList.map(
          (protocol) => protocol.url
      )

      // 处理协议弹框顶部的多个协议
      if (this.product.bigAgreementName) {
        // api协议在获取时已经成为数组，因此不用重复调用split方法，其他为本地配置的协议时字段为字符串，因此改为数组
        const bigAgreementNameList = this.product.bigAgreementName
        const bigAgreementFileList = this.product.bigAgreementFile
        if (bigAgreementNameList.length === 1) {
          this.bigAgreementName.push(...bigAgreementNameList)
          this.bigAgreementFile.push(...bigAgreementFileList)
        } else {
          bigAgreementNameList.splice(1, 0, "个人信息授权书")
          bigAgreementFileList.splice(1, 0, "")
          this.bigAgreementName = bigAgreementNameList
          this.bigAgreementFile = bigAgreementFileList
          this.protocolsTitleIndex = 1
          this.$nextTick(() => {
            this.protocolsTitleIndex = 1
          })
        }
      }

      // 获取用户信息填入协议
      const userInfo = this.$getUserInfo()
      const userName = userInfo.userName
          .split("")
          .map((item, index) => {
            if (index === 0) {
              return item
            } else {
              return "*"
            }
          })
          .join("")

      // 身份证号判空
      userInfo.idCard = userInfo.idCard ? userInfo.idCard : ""

      // 协议内容填充
      this.protocolContentHtml = this.protocolContentHtml.replace(
          "{name}",
          userName
      )
      this.protocolContentHtml = this.protocolContentHtml.replace(
          "{idCard}",
          userInfo.idCard.replace(/^(.{6})(?:\d+)(.{4})$/, "\$1********\$2")
      )
      this.protocolContentHtml = this.protocolContentHtml.replace(
          "{time}",
          getNowDate()
      )
      this.protocolContentHtml = this.protocolContentHtml.replace(
          "{institutionName}",
          this.product.name || ""
      )
      this.protocolContentHtml = this.protocolContentHtml.replace(
          "{companyName}",
          this.product.companyName || ""
      )
      this.protocolContentHtml = this.protocolContentHtml.replace(
          "{address}",
          this.product.address || ""
      )
      this.protocolContentHtml = this.protocolContentHtml.replace(
          "{productName}",
          this.product.productName || ""
      )
      this.protocolContentHtml = this.protocolContentHtml.replace(
          "{productCompanyName}",
          this.product.productCompanyName || ""
      )

      // 弹出协议
      this.protocolPopupIsShow = true

      // 截图状态开始时不可以点击提交
      this.stopSubmit = true
      // 弹出协议之后进行截图，截图需要等弹出动画结束
      setTimeout(() => {
        this.html2canvas()
      }, 300)
    },
    doSubmit() {
      localStorage.setItem("submitSuccess", "1")
      this.countDownCoverIsShow = true
      setTimeout(() => {
        this.doJump()
      }, 1500)
    },
    // 点击协议内容的同意并申请后进行的产品申请及跳转
    async doJump() {
      // 该渠道不申请任何产品，直接跳转审核中页面
      if (process.env.VUE_APP_TO_IN_REVIEW_AFTER_SUBMIT) {
        await this.$router.replace("/inReview")
        return
      }

      // 根据返回产品的jumpState字段判断后续是否跳转贷超(0跳转 1不跳转)
      localStorage.setItem("quickLoanToSuperLoan", this.product.jumpState)

      // 获取截图，在申请大额贷时调用传协议截图的接口
      const base64Img = localStorage.getItem("base64Img")
      const file = base64Img ? this.base64ToFile(base64Img, "image") : ""
      save_personal_protocol_image({
        data: file,
        ...this.product
      })

      try {
        // 大额贷申请产品，api进件
        const { data } = await apply_big_loan_product({
          productId: this.product.id,
          instApiState: this.product.instApiState,
          bigLoanType: this.product.isKlhdProduct
        })

        // api产品
        if (this.product.instApiState === 1 && data.result) {
          // api产品进件后要埋点
          api_product_user_apply({channelSign: getSearch().channelSign})

          // 本地api产品，直接推送，然后跳转中间页即可
          if (this.product.localState === 1) {
            // 推送api产品
            await apply_api_product(this.product.id)

            // 跳转中间页
            await this.$router.replace("/apiProductMiddlePage")
            return
          }

          // 分润Api，直接跳转中间页
          if (this.product.localState === 2) {
            await this.$router.replace("/apiProductMiddlePage")
            return
          }

          // h5Api直接跳链接
          if (this.product.localState === 0) {
            // 特殊渠道api的h5也直接跳下载
            if (process.env.VUE_APP_H5_API_PRODUCT_TO_DOWNLOAD) {
              this.$toDownload(0)
              return
            }

            // 跳链接
            location.replace(data.productDto.h5Url)
            return
          }
        }

        // 这里是whole-process-51渠道的特殊配置
        if (process.env.VUE_APP_BIG_LOAN_PRODUCT_AFTER_APPLY_TO_DOWNLOAD) {
          this.$toDownload(0)
          return
        }

        if (!data.result) {
          this.$toast.fail('该产品已下架')

          // 进件失败跳贷超
          await this.$router.replace('/superLoan')
          return
        }

        // 存储字段，大额贷申请成功
        localStorage.setItem("bigLoanProductApplySuccess", "1")
        await this.$router.replace("/examining")
      } catch (e) {
        this.countDownCoverIsShow = false
      }

      this.countDownCoverIsShow = false
    },
    // 用户点击了协议弹出框下方的 我不同意 按钮
    refuseProtocol() {
      // 首先关闭协议弹窗
      this.protocolPopupIsShow = false
      // 等关闭动画300ms结束，弹出再次询问的弹框
      setTimeout(() => {
        this.refuseProtocolPopupIsShow = true
      }, 300)
    },
    // 用户取消申请
    applyCancel() {
      // 该渠道不会申请任何产品，老用户取消后清除缓存，回到注册页
      if (process.env.VUE_APP_TO_IN_REVIEW_AFTER_SUBMIT) {
        localStorage.clear()
        this.$router.replace("/register")
        return
      }

      // 该渠道直接跳失败，并且永远无法到达贷超
      if (process.env.VUE_APP_FAILED_TO_FAIL_PAGE) {
        this.$router.replace("/fail")
        return
      }

      // 该渠道直接跳失败，但是成功后的流程是正常的，能够前往贷超
      if (process.env.VUE_APP_FAILED_TO_FAIL_PAGE_BUT_CAN_TO_SUPER_LOAN) {
        this.$router.replace("/fail")
        return
      }

      // 该渠道没有匹配到产品就直接跳下载页面
      if (process.env.VUE_APP_FAILED_TO_DOWNLOAD) {
        this.$toDownload(0)
        return
      }

      localStorage.setItem("submitSuccess", "1")
      this.$router.replace("/superLoan")
    },
    // 截图
    getShareImgBase64() {
      return new Promise((resolve) => {
        setTimeout(() => {
          html2canvas(document.querySelector("#match-big-loan-product"), {
            // useCORS: true, // 【重要】开启跨域配置
            scale: 1
          }).then((canvas) => {
            const imgData = canvas.toDataURL("image/jpeg", 1.0)
            resolve(imgData)
          })
        }, 800)
      })
    },
    html2canvas() {
      this.$nextTick(() => {
        this.getShareImgBase64().then((res) => {
          localStorage.setItem("base64Img", res)
          // 截图完成可以提交资料
          this.stopSubmit = false
        })
      })
    },
    // 转换截图为file
    base64ToFile(data, fileName) {
      const dataArray = data.split(",")
      const byteString = atob(dataArray[1])
      const options = {
        type: "image/jpeg",
        endings: "native"
      }
      const utf8Array = new Uint8Array(byteString.length)
      for (let i = 0; i < byteString.length; i++) {
        utf8Array[i] = byteString.charCodeAt(i)
      }
      const formData = new FormData()
      let fileOfBlob = new File([utf8Array], fileName + ".jpg", options) //返回文件流
      formData.append("image", fileOfBlob)
      return formData
    }
  },
  created() {
    // 下面两个函数是用来获取配置项和是否跳下载的开关有没有开启，不用管
    this.getConfig()

    /*
     * 这里是整个页面的协议弹框弹出之前的运行步骤
     * 1.函数中会获取个人信息授权书，并对富文本内容进行处理后赋值给protocolContentHtml字段
     * 2.在协议函数执行完毕后会运行matchProduct函数，该函数只匹配产品，并将匹配到的产品结果赋值给product字段，并在接口响应后将productHasBeenGet改为true
     * 3.由于页面加载完dom后会开启一个为时3s的加载动画，当waitingTime字段大于2时，代表3s的动画应该结束
     * 4.在监听器中有waitingEndAndProductHasBeenGet字段，该字段依赖于条件2,3中的状态，当该值为true时，调用afterMatchProductAndBeforePopupProtocol函数
     * 5.上述afterMatchProductAndBeforePopupProtocol函数是页面弹出协议之前的函数，该函数中会判断产品的类型，决定最终是弹出协议还是直接进行跳转
     * */
    this.getProtocolContentHtml()
  },
  mounted() {
    // 这里是进入页面的倒计时动画
    setInterval(() => {
      this.waitingTime++
    }, 1000)
  }
}
</script>

<style lang="less" scoped>
#match-big-loan-product {
  height: 100vh;
  background: #fff;

  .waiting-loading {
    width: 100vw;
    height: 100vh;
    padding-top: 1.36rem;

    .background {
      width: 8rem;
      margin: 0 auto 1.547rem;
    }

    main {
      padding: 0 1.467rem;

      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.693rem;
        font-weight: 500;
        font-size: 0.373rem;
        color: #999999;

        img {
          width: 0.8rem;
        }

        .text-active {
          color: #333333;
        }
      }
    }
  }

  .count-down-cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #fff;
    z-index: 3000;

    img {
      width: 4.267rem;
      margin: 3.44rem auto 0;
    }
  }

  .protocol-popup-container {
    overflow: hidden;
    width: 100vw;
    background: #ffffff;

    .protocol-content-container {
      width: 100%;
      height: 322px;
      padding: 0 29px;
      margin-bottom: 20px;
      font-size: 14px;

      ::v-deep .van-tab--active .van-tab__text {
        color: #000;
        font-size: 16px;
        font-weight: 600;
      }

      ::v-deep .van-tab {
        padding: 0 10px;
      }

      .protocol-content {
        overflow-y: auto;
        padding-top: 10px;
      }

      .protocol-content-no-scroll {
        overflow: hidden;
      }

      .protocol-content,
      iframe {
        width: 100%;
        height: 278px;
      }
    }

    .protocol-do-agree-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 7.733rem;
      height: 1.333rem;
      margin: 0 auto 0.4rem;
      background: #325ef3;
      border-radius: 0.667rem;
      font-size: 0.427rem;
      font-weight: 500;
      color: #ffffff;
    }

    .protocol-refuse-button {
      padding-bottom: 0.4rem;
      font-size: 0.373rem;
      text-align: center;
      color: #999999;
    }
  }

  .refuse-protocol-popup-container {
    width: 9.467rem;
    padding: 0.533rem 0.747rem;
    background: #ffffff;
    text-align: center;

    h3 {
      margin-bottom: 0.373rem;
      font-weight: 600;
      font-size: 0.533rem;
      color: #333333;
    }

    p {
      font-size: 0.48rem;
      color: #333333;
      line-height: 0.667rem;
    }

    img:first-of-type {
      margin: 0.667rem 0 0.293rem;
    }
  }
}
</style>
